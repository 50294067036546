var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "gc-search-domain-slide",
        {
          staticClass: "global-components",
          attrs: {
            slide: _vm.slide,
            loading: _vm.loading,
            "loading-tlds": _vm.loadingTlds,
            "search-in-progress": _vm.searchInProgress,
            "domain-suggestions": JSON.stringify(_vm.domainSuggestions),
            "domain-name-input": _vm.domainNameInput,
            "available-tlds": _vm.tlds,
            "max-selected-domains": 1,
            "selected-domains": JSON.stringify(_vm.selectedDomains),
            "domain-results": JSON.stringify(_vm.domainSearchResults),
          },
          on: {
            transferDomain: _vm.handleTransferDomain,
            useOwnDomain: _vm.handleUseOwnDomain,
            searchDomainNameWithTld: _vm.submit,
            "update:domainNameInput": _vm.handleDomainNameInputUpdated,
            domainSelected: _vm.handleDomainSelected,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "loading-container",
              attrs: { slot: "loading" },
              slot: "loading",
            },
            [_c("ct-centered-spinner")],
            1
          ),
        ]
      ),
      _c("domain-transfer-modal", {
        attrs: { bus: _vm.bus },
        model: {
          value: _vm.showDomainTransferModal,
          callback: function ($$v) {
            _vm.showDomainTransferModal = $$v
          },
          expression: "showDomainTransferModal",
        },
      }),
      _c("standalone-hosting-modal", {
        attrs: { bus: _vm.bus },
        on: {
          "next-slide": function ($event) {
            return _vm.$emit("next-slide")
          },
        },
        model: {
          value: _vm.showStandaloneHostingModal,
          callback: function ($$v) {
            _vm.showStandaloneHostingModal = $$v
          },
          expression: "showStandaloneHostingModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }