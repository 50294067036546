<template>
  <div>
    <gc-search-domain-slide
      class="global-components"
      :slide="slide"
      :loading="loading"
      :loading-tlds="loadingTlds"
      :search-in-progress="searchInProgress"
      :domain-suggestions="JSON.stringify(domainSuggestions)"
      :domain-name-input="domainNameInput"
      :available-tlds="tlds"
      :max-selected-domains="1"
      :selected-domains="JSON.stringify(selectedDomains)"
      :domain-results="JSON.stringify(domainSearchResults)"
      @transferDomain="handleTransferDomain"
      @useOwnDomain="handleUseOwnDomain"
      @searchDomainNameWithTld="submit"
      @update:domainNameInput="handleDomainNameInputUpdated"
      @domainSelected="handleDomainSelected"
    >
      <div slot="loading" class="loading-container">
        <ct-centered-spinner />
      </div>
    </gc-search-domain-slide>

    <domain-transfer-modal
      v-model="showDomainTransferModal"
      :bus="bus"
    />
    <standalone-hosting-modal
      v-model="showStandaloneHostingModal"
      :bus="bus"
      @next-slide="$emit('next-slide')"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'
import { logDomainInteraction } from '@/components/StagelineV2/slides/serviceConfiguration/services/helper'


const TOASTS = {
  success: {
    domainCreatedSuccess: 'Domain has been created.',
    hostingCreatedSuccess: 'Hosting has been created.',
  },
  errors: {
    domainCreatedError: 'Unable to create domain.',
    hostingCreatedError: 'Unable to create web hosting.',
    selectDomainError: 'Select a domain.',
    genericError: 'Something went wrong, please try again later!',
  },
}

export default {
  name: 'SearchDomainSlide',
  components: {
    CtCenteredSpinner:       () => import('@/components/shared/CtCenteredSpinner.vue'),
    DomainTransferModal:     () => import('@/components/Services/Domains/DomainTransferModal'),
    StandaloneHostingModal:  () => import('@/components/Services/Hosting/StandaloneHostingModal.vue'),
  },
  mixins: [makeToastMixin],
  props: {
    slide: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      bus: new Vue(),
      loading: false,
      loadingTlds: false,
      searchInProgress: false,
      domainNameInput: '',
      selectedDomains: [],
      showDomainTransferModal: false,
      showStandaloneHostingModal: false,
      savingMessage: '',
      slideStartTime: null,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'company',
      'ghostMode',
    ]),
    ...mapGetters('domainSuggestions', [
      'domainSuggestions',
    ]),
    ...mapGetters('domains', [
      'tlds',
      'domainSearchResults',
      'selectedDomain',
      'domain',
      'webHosting',
      'hostingTransferInfo',
      'standaloneHostingInfo',
      'activeDomainTransferRequest',
    ]),
    ...mapGetters('vouchers', [
      'voucherByProductCategory',
      'accountDomainVouchers',
      'unRedeemedVoucherByProductCategoryCompanyId',
    ]),
    domainSetupComplete() {
      return this.domainCreated && !this.activeDomainTransferRequest && this.noAvailableEmailOrHostingVoucher
    },
    domainCreated() {
      return this.domainVoucher && !this.hasUnredeemedDomainVoucher
    },
    domainVoucher() {
      return this.voucherByProductCategory('business-domain')
    },
    hasUnredeemedDomainVoucher() {
      return !!this.unRedeemedVoucherByProductCategoryCompanyId('business-domain', this.company.id)
    },
    noAvailableEmailOrHostingVoucher() {
      return !this.hostingVoucherAvailable() && !this.emailVoucherAvailable()
    },
    hostingVoucherAvailable() {
      return !!this.unRedeemedVoucherByProductCategoryCompanyId('business-website-hosting', this.company.id)
    },
    emailVoucherAvailable() {
      return !!this.unRedeemedVoucherByProductCategoryCompanyId('business-email', this.company.id)
    },
    shouldCreateHostingWithDomain() {
      return this.hostingVoucherAvailable() || this.emailVoucherAvailable()
    },
  },
  watch: {
    domainSelected(newValue) {
      if (newValue) this.scrollToBottom()
    },
  },
  async mounted() {
    await Promise.all([
      this.loadTlds(),
      this.initializeDomainSlide(),
    ])
  },
  beforeDestroy() {
    this.bus.$off('scroll-to-bottom', this.scrollToBottom)
    this.bus.$off('navigate', this.completeSlideInteraction)
    this.bus.$off('domain-selected')
    this.bus.$off('log-domain-interaction')
  },
  methods: {
    ...mapActions('domains', [
      'fetchTlds',
      'searchDomains',
      'setSelectedDomain',
      'setDomainSearchResults',

      'createDomain',
      'addService',
    ]),
    ...mapActions('companies', [
      'loadActiveServiceByType',
    ]),
    ...mapActions('vouchers', [
      'fetchAccountDomainVouchers',
    ]),
    ...mapActions('domainSuggestions', [
      'fetchDomainSuggestions',
      'clearSuggestions',
    ]),

    async loadTlds() {
      this.loadingTlds = true
      await this.fetchTlds()
      this.loadingTlds = false
    },

    async initializeDomainSlide() {
      this.loading = true

      try {
        await this.fetchCompanyVouchers(this.company.id)
        if (this.showDomainSearch && !this.domainAdminSwap) {
          await this.generateDomainSuggestions()
        }
        this.createClientSlideInteraction()
        await this.setupBusListeners()
      }
      catch(_error) {
        this.errorToast('Error', TOASTS.errors.genericError)
      }
      finally {
        this.loading = false
      }
    },

    async generateDomainSuggestions() {
      this.loading = true
      await this.fetchDomainSuggestions(this.company.name)
      this.loading = false
    },

    async handleTransferDomain() {
      await this.bus.$emit('navigate', 'domain-transfer-modal')
      await this.bus.$emit('log-domain-interaction', { name: 'domain-transfer-click' })
      this.showDomainTransferModal = true
    },

    async handleUseOwnDomain()  {
      await this.bus.$emit('navigate', 'standalone-hosting-modal')
      this.showStandaloneHostingModal = true
    },

    handleDomainNameInputUpdated(event) {
      this.domainNameInput = event?.detail?.[0] || ''
    },

    async submit() {
      this.selectedDomains = []
      await this.searchDomains(null)
      await this.setDomainSearchResults([])
      this.searchInProgress = true

      //Remove old domain suggestions, search for domains.
      if (!this.domainSuggestions.map(suggestion => suggestion.domain).includes(this.domainNameInput)) {
        await this.clearSuggestions()
      }
      await this.searchDomains(this.domainNameInput)

      this.searchInProgress = false
    },

    async handleDomainSelected(domain) {
      await this.setSelectedDomain(domain)
    },

    createClientSlideInteraction() {
      if (!this.ghostMode) this.slideStartTime = Date.now()
    },

    async setupBusListeners() {
      this.bus.$on('scroll-to-bottom', this.scrollToBottom)
      this.bus.$on('navigate', async () => { await this.completeSlideInteraction() })
      this.bus.$on('domain-selected', (selected) => { this.domainSelected = selected })
      this.bus.$on('log-domain-interaction', async () => { await logDomainInteraction })
    },

    async completeSlideInteraction() {
      if(!this.slideStartTime) return

      await this.logInteraction({
        name: 'slide_interaction',
        slideStartTime: this.slideStartTime,
      })
    },

    scrollToBottom() {
      this.$nextTick(() => {
        setTimeout(() => {
          window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
          })
        }, 200)
      })
    },

    async nextStep() {
      if (this.domainCreated) {
        this.$emit('next-slide')
        return
      }

      if (!this.validateSelectedDomain()) return

      this.loading = true

      try {
        const domainCreated = await this.tryCreateDomain()
        if (!domainCreated) return

        const hostingCreated = await this.tryCreateHosting()
        if (!hostingCreated) return

        this.$emit('next-slide')
      } catch (_error) {
        this.errorToast('Error', TOASTS.errors.genericError)
      } finally {
        this.loading = false
      }
    },

    validateSelectedDomain() {
      if (this.selectedDomain) return true

      this.errorToast('Error', TOASTS.errors.selectDomainError)
      return false
    },

    async tryCreateDomain() {
      const success = await this.createDomainRecord()
      if (!success) return false

      await this.completeSlideInteraction()
      await this.logInteraction({
        name: 'choose-domain',
        action: 'create',
        subCategory: 'business-domain',
        objectTable: 'Domain',
        objectId: this.domain.id,
      })

      return true
    },

    async tryCreateHosting() {
      const success = await this.createHostingRecord()
      if (!success) return false

      await this.logInteraction({
        name: 'choose-domain-hosting',
        action: 'create',
        subCategory: 'business-website-hosting',
        objectTable: 'Domain Hosting Info',
        objectId: this.webHosting.id,
      })

      return true
    },

    async logInteraction(details) {
      try {
        await logDomainInteraction(details)
      } catch (_error) { /* suppress error */ }
    },

    async createDomainRecord() {
      try {
        this.savingMessage = 'Creating Domain...'
        const result = await this.createDomain(this.company.id)

        if (result?.success) {
          await this.loadActiveServiceByType({ id: this.company.id, type: 'business-domain' })
          this.successToast('Success', TOASTS.success.domainCreatedSuccess)
          return true
        }

        this.errorToast('Error', TOASTS.errors.domainCreatedError)
        return false
      } catch(_error) {
        this.errorToast('Error', TOASTS.errors.genericError)
        return false
      }
    },

    async createHostingRecord() {
      if (!this.shouldCreateHostingWithDomain()) return true

      try {
        this.savingMessage = 'Creating hosting...this can take a few minutes. Please do not refresh the page.'
        const result = await this.addService({ domainId: this.domain.id })

        if (result?.success) {
          const serviceTypes = ['business-website-hosting', 'business-email']
          for (const serviceType of serviceTypes) {
            await this.loadActiveServiceByType({ id: this.company.id, type: serviceType })
          }

          this.successToast('Success', TOASTS.success.hostingCreatedSuccess)
          return true
        }

        this.errorToast('Error', TOASTS.errors.hostingCreatedError)
        return false
      } catch(_error) {
        this.errorToast('Error', TOASTS.errors.genericError)
        return false
      }
    },
  },
}
</script>
